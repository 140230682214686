<template>
  <v-app>
    <v-main v-if="!show_result && !show">
      <v-layout v-if="loading" align-center justify-center column fill-height>
        <v-flex row align-center>
          <div class="justify-center text-center">
            <v-progress-circular :size="100" color="primary" indeterminate/>
          </div>
        </v-flex>
      </v-layout>
      <v-container v-if="!loading" width="30%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-row>
          <v-col>
            <v-card flat>
              <v-toolbar dense flat>
                <v-toolbar-title>{{ translate("search_certificate") }}</v-toolbar-title>
                <v-spacer/>
                <v-btn-toggle light v-model="language">
                  <v-btn small value="nl"> NL </v-btn>
                  <v-btn small value="en"> EN </v-btn>
                </v-btn-toggle>
              </v-toolbar>
              <v-divider/>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      single-line
                      :label="translate('last_name')"
                      v-model="last_name"/>
                    <v-text-field
                      single-line
                      :label="translate('date_of_birth')"
                      v-model="date_of_birth"
                      v-mask="'##-##-####'"
                      placeholder="dd-mm-yyyy"/>
                    <v-text-field
                      single-line
                      :label="translate('place_of_birth')"
                      v-model="place_of_birth"/>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-footer>
                <v-row>
                  <v-col>
                    <v-btn block text x-large @click="search()">
                      <v-icon class="mr-2">mdi-magnify</v-icon>
                      <small>{{ translate("search") }}</small>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-footer>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="no_result" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-alert text type="error" prominent border="left">
          {{ translate("nothing_found") }}
        </v-alert>
      </v-container>
    </v-main>
    <v-card flat v-if="show_result" :loading="loading">
      <v-toolbar dense flat>
        <v-btn icon @click="show_result = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
      </v-toolbar>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col lg="3" md="3" sm="5" xs="12" class="d-flex justify-center grey lighten-4 pt-9">
            <div class="text-center">
              <v-avatar size="150">
                <v-img class="display:block" contain max-width="150px" :src="record.candidate_avatar"/>
              </v-avatar>
              <br />
              <div class="text-center" style="width: 100%">
                <h3 class="mt-5" v-if="!loading" color="secondary">{{ translate(record.candidate_name) }}</h3>
                <br />
                <p v-if="!loading">{{ record.scheme_name }}</p>
                <v-chip v-if="!loading && record.status" class="mt-5" :color="getColor">
                  {{ translate(record.status) }}
                </v-chip>
              </div>
            </div>
          </v-col>
          <v-divider vertical/>
          <v-col>
            <base-row
              class="mt-5"
              v-if="!loading"
              :label="translate('person')"
              :value="record.candidate_name"
              :hide_copy="true"/>
            <base-row
              v-if="!loading"
              :label="translate('place_of_birth')"
              :value="record.place_of_birth"
              :hide_copy="true"/>
            <base-row
              v-if="!loading"
              :label="translate('date_of_birth')"
              :value="moment(record.date_of_birth).format('DD-MM-YYYY')"
              :hide_copy="true"/>
            <base-row
              v-if="!loading"
              :label="translate('scheme')"
              :value="record.scheme_name"
              :hide_copy="true"/>
            <base-row
              v-if="!loading"
              :label="translate('number')"
              :value="record.certificate_number"
              :hide_copy="true"/>
            <base-row
              v-if="!loading"
              :label="translate('issued_on')"
              :value="moment(record.issueing_date).format('DD-MM-YYYY')"
              :hide_copy="true"/>
            <base-row
              v-if="record.status === 'valid' && record.valid_until"
              :label="translate('valid_until')"
              :value="moment(record.valid_until).format('DD-MM-YYYY')"
              :hide_copy="true"/>
            <base-row
              v-if="record.status !== 'valid' && !loading"
              :label="translate('valid_until')"
              value="-"
              :hide_copy="true"/>
            <base-row
              v-if="!loading"
              :label="translate('issued_by')"
              :value="record.examBuro"
              :hide_copy="true"/>
            <v-img
              class="display:block"
              contain
              max-width="150px"
              :src="record.logo"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-if="show" :loading="loading">
      <v-toolbar dense flat>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $store.getters.translate("scan_qr_code") }}</v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-divider/>
      <v-card-text>
        <qrcode-stream v-if="show" @decode="onDecode"/>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("certificate_check"),
        name: "certificate_check",
      },
      title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Perscriptum",
      record: {
        name: "",
      },
      loading: false,
      tab: 0,
      pdf: "",
      translations: [],
      language: "nl",
      date_of_birth: null,
      place_of_birth: null,
      last_name: null,
      show: false,
      show_result: false,
      id: null,
      no_result: false,
    };
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
    this.getInfo();
    this.getTranslations();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/certificatecheck?sign_transaction_number=" + this.id)
        .then((response) => {
          this.record = response.data;
          this.loading = false;
          this.no_result = false;
          this.show_result = true;
        })
        .catch(() => {
          this.no_result = true;
          this.loading = false;
        });
    },
    search() {
      this.loading = true;
      let date_of_birth = this.date_of_birth ? "?date_of_birth=" + this.date_of_birth : "";
      let place_of_birth = this.place_of_birth ? "&place_of_birth=" + this.place_of_birth : "";
      let last_name = this.last_name ? "&last_name=" + this.last_name : "";
      this.$http
        .get(this.$store.getters.appUrl + "v2/certificatecheck" + date_of_birth + place_of_birth + last_name)
        .then((response) => {
          this.record = response.data;
          this.loading = false;
          this.no_result = false;
          this.show_result = true;
        })
        .catch(() => {
          this.record = {};
          this.no_result = true;
          this.show_result = false;
          this.loading = false;
        });
    },
    onDecode(decodedString) {
      this.id = decodedString.split("id=")[1];
      this.show = false;
    },
    async getTranslations() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/translations")
        .then((response) => {
          this.translations = response.data;
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    translate(payload) {
      if (this.translations.length > 0) {
        let result = this.translations.filter((translation) => translation.key === payload && translation.language === this.language);
        if (result.length > 0) {
          return result[0].value;
        }
        return payload;
      }
      return payload;
    },
    async getInfo() {
      this.$vuetify.theme.themes.light.primary = "#FFFFFF";
      this.$vuetify.theme.themes.light.secondary = "#FFFFFF";
      this.$http
        .get(this.$store.getters.appUrl + "v2/info")
        .then((response) => {
          this.info = response.data;
          this.$store.dispatch("setColors", {
            primary_color: response.data.primary_color,
            secondary_color: response.data.secondary_color,
          });
          this.$vuetify.theme.themes.light.primary = response.data.primary_color;
          this.$vuetify.theme.themes.light.secondary = response.data.secondary_color;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
  },
  computed: {
    getColor() {
      if (this.record.status === "valid") {
        return "success";
      }
      if (this.record.status === "pending_approval") {
        return "orange";
      }
      if (this.record.status === "revoked") {
        return "error";
      }
      if (this.record.status === "expired") {
        return "error";
      }
      return "secondary";
    },
    getStatus() {
      return this.translate(this.record.status);
    },
  },
  watch: {
    id: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style>
.v-toolbar__image {
  opacity: 0.2 !important;
}
img {
  width: 200px;
}
</style>